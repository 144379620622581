var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignupTout = {
    templateContainer: $(),
    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form')
          ? $emailContainerNode
          : $('form[name="email_signup"]', $emailContainerNode);
        var $emailError = $('.js-email-error', $emailContainerNode);
        var $emailToolTip = $('.js-email-tooltip', $emailContainerNode);
        var $smsError = $('.js-sms-error', $emailContainerNode);
        var $emailSuccess = $('.js-thank-you-text', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailForm);
        var $emailSubmit = $('.js-email-signup-link', $emailContainerNode);
        var $emailSubmitBtn = $('.js-email-signup-submit', $emailContainerNode);
        var $smsForm = $('form[name="sms_signup"]', $emailContainerNode);
        var $smsInput = $('input[name="MOBILE_NUMBER"]', $smsForm);
        var $smsSubmit = $('.js-sms-signup-link', $smsForm);
        var $smsSubmitBtn = $('.js-sms-signup-submit', $smsForm);
        var $toutSubtitle = $('.js-signup-tout-subtitle', $emailContainerNode);
        var $smsLegalCopy = $('.js-sms-signup-legal-copy', $emailContainerNode);
        var $languageIDInput = $('input[name="LANGUAGE_ID"]', $emailContainerNode);

        if (Drupal.settings.common.has_onelink_trans) {
          if (generic.cookie('LOCALE') === Drupal.settings.common.locate_one_link_translation) {
            $languageIDInput.val(Drupal.settings.common.lang_id_one_link_translation);
          }
        }

        // Submit Email form
        $emailSubmit.on('click', function () {
          $emailSubmitBtn.trigger('click');
        });

        // Submit SMS form
        $smsSubmit.on('click', function () {
          $smsSubmitBtn.trigger('click');
        });

        // Once user starts typing into email form, SMS form will appear
        $emailInput.on('keypress', function () {
          //  $smsForm.removeClass('hidden'); // we are launching without sms on the tout. Will reactivate later.
          //  $smsLegalCopy.removeClass('hidden');
        });

        $emailForm.once('email-signup__form').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailError.addClass('hidden');
          $smsError.addClass('hidden');
          $emailInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function () {
              if (site.client.isMobile) {
                $('body, html').animate({ scrollTop: 0 }, 400);
              }
              $emailSuccess.removeClass('hidden');
              $emailForm.addClass('hidden');
              $emailToolTip.addClass('hidden');
              // If user has opted for email, then show SMS optin
              if ($smsForm.is(':visible')) {
                $smsSubmit.removeClass('hidden');
              }
            },
            onFailure: function () {
              $emailError.removeClass('hidden');
              $emailInput.addClass('error');
            }
          });
        });

        $smsForm.once('sms-signup__form').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailError.addClass('hidden');
          $smsError.addClass('hidden');
          $emailInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($smsForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          if ($.cookie('csrftoken')) {
            params['_TOKEN'] = $.cookie('csrftoken');
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function () {
              if (site.client.isMobile) {
                $('body, html').animate({ scrollTop: 0 }, 400);
              }
              $emailSuccess.removeClass('hidden');
              $smsForm.addClass('hidden');
              $toutSubtitle.addClass('hidden');
              $smsLegalCopy.addClass('hidden');
            },
            onFailure: function () {
              $smsError.removeClass('hidden');
              $smsInput.addClass('error');
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.emailSignupTout = {
    attach: function (context) {
      site.emailSignupTout.templateContainer = $('.signup-tout-wrapper', context);
      site.emailSignupTout.initEmailSignup();
      site.emailSignupTout.templateContainer.each(function () {
        var $module = $(this);
        var $open = $('.su-tout-details', $module);

        $open.on('click.open', function (event) {
          event.preventDefault();
          $module.toggleClass('details-active');
        });
      });
    }
  };
})(jQuery);
